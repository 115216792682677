import React from 'react'

const Intro = () => {

    return (

        <div className = "intro-container">
            <div className="intro-size">
                <h4 className = "greeting"><b className ="important">&nbsp;Hi there! My name's</b></h4>
                <h1 className = "name" id = "name">Philip Fielding</h1>
                <h4 className ="intro">I make things for the Web</h4>
            </div>
        </div>

    )
}

export default Intro
    