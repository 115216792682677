import React from 'react'

const Footer = () => {
    return (
        <div className ="footer-section">
            <div><a href ="https://github.com/PhilwithoneL/philip-fielding-portfolio" className =" center-div footer">@ 2022 Philip R. Fielding</a></div>
        </div>
    )
}

export default Footer;